import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CJumbotron02,
  CBreadCrumb,
  LWrap,
  CPagingList,
  CUtilityBtnList,
  CNewsList,
  CSearch,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: {
    limit: number;
    skip: number;
    totalCount: number;
    current: number;
  };
}) => {
  const frontmatter = {
    title: 'インフォメーション ',
    description:
      'ロイヤルパークホテル（東京・日本橋）公式サイト。「お知らせ」のご案内。日本橋ロイヤルパークホテルのお知らせ一覧を掲載しています。詳細はこちらのページから。',
  };

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron02
        size="small"
        data={{
          title: {
            main: <>お知らせ</>,
            sub: <>INFORMATION</>,
          },
        }}
      ></CJumbotron02>
      {/* <CJumbotron
        size="small"
        data={{
          title: {
            main: 'お知らせ',
            sub: 'NEWS',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/information/kv.png',
              },
              imgSp: {
                src: '/assets/images/information/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron> */}
      <section className="l_sect">
        <LWrap>
          <ul className="c_tab_btnList">
            <li>
              <Link to="/information/" className="is_active">
                お知らせ
              </Link>
            </li>
            <li>
              <Link to="/release/">ニュースリリース</Link>
            </li>
          </ul>
          <CNewsList
            data={infoChoice(
              infoGet(),
              [],
              pageContext.limit + pageContext.skip,
              pageContext.skip
            )}
          />
          <CPagingList
            totalCount={pageContext.totalCount}
            path="/information/"
            perPage={pageContext.limit}
            current={pageContext.current}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CSearch formId="global" />
    </Layout>
  );
};

export default SubPage;
